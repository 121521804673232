export const menuHandler = () => {
	const closeMenu = () => {
		const menuToggleContainer = document.getElementById("navbarSupportedContent")
		const selectedMenuElement = menuToggleContainer.querySelector("a.show")
		const openMenuElement = menuToggleContainer.querySelector("ul.show")

		if (openMenuElement) {
			selectedMenuElement.classList.remove("show")
			selectedMenuElement.blur()
			openMenuElement.classList.remove("show")
		}
	}

	const removeFocus = () => {
		const menuToggleContainer = document.getElementById("navbarSupportedContent")
		const selectedMenuElement = menuToggleContainer.querySelector("a")
		const openMenuElement = menuToggleContainer.querySelector("ul.show")

		if (!openMenuElement) {
			selectedMenuElement.blur()
		}
	}

	return {closeMenu, removeFocus}
}
